<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
    @update:return-value="$emit('input', $event)"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="time"
        :label="label"
        prepend-icon="mdi-clock-time-four-outline"
        readonly
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <v-time-picker
      v-if="menu"
      v-model="time"
      full-width
      @click:minute="$refs.menu.save(time)"
    />
  </v-menu>
</template>

<script>
export default {
  name: 'FormTimePicker',
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      menu: false,
      time: ''
    };
  },
  mounted() {
    this.time = this.value;
  }
};
</script>

<style></style>
